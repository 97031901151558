<template>
  <div class="placeholder">
    <img
      :src="dark ? getImgUrl('logo-big') : getImgUrl('logo-big-black')"
      alt="EarnForex"
    />
    <div class="placeholder-text">{{ placeholderText }}</div>
  </div>
</template>

<script>
export default {
  name: "placeholderBlock",
  props: {
    placeholderText: String,
    dark: Boolean,
  },
  methods: {
    getImgUrl(name) {
      return require("@/assets/img/" + name + ".png");
    },
  },
};
</script>
